export type CurrencyFormatOptions = Omit<
  Intl.NumberFormatOptions,
  'maximumFractionDigits'
> &
  Partial<{
    maxDecimals?: number
  }>

export const formatCurrency = (
  val: string | number = 0,
  {
    maxDecimals = 2,
    currency = 'EUR',
    trailingZeroDisplay = 'stripIfInteger',
    ...opts
  }: CurrencyFormatOptions = {}
) => {
  if (typeof val === 'string') {
    val = parseFloat(val)
  }

  return `${new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    trailingZeroDisplay,
    maximumFractionDigits: maxDecimals,
    ...opts
  }).format(val)}`
}
